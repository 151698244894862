import { Flash } from "@/types";
import { usePage } from "@inertiajs/react";
import { Alert, AlertProps, Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";

// Render an alert message from global shared props given by backend session flash
export default function AlertMessage(props: AlertProps) {
    // TODO: find a way to type hint shared props without explicit casting
    const sharedProps = usePage().props;
    const flash = sharedProps.flash as Flash;
    const [alertOpen, setAlertOpen] = useState<boolean>(true);

    useEffect(() => {
        if (flash?.severity === 'error') {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }
    }, [flash]);

    if (!flash?.message || !flash?.severity) {
        return null;
    }

    return (
        <Collapse in={alertOpen}>
            <Alert
                {...props}
                severity={flash.severity}
                // Make the alert uncloseable for now, because subsequent errors won't show if it has been closed by user, since the state is preserved on most pages
                // TODO: find a workaround for this
                // onClose={() => setAlertOpen(false)}
            >
                {flash.message}
            </Alert>
        </Collapse>
    );
}